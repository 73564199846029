import React, { Component } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "../scss/contact.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { setDefaultHeaderTheme } from "../utils/common-util"
import { getContactInfos } from "../data/contact"

class ContactPage extends Component {
  componentDidMount() {
    setDefaultHeaderTheme(true)

    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.id = "meeting-widget"
    script.setAttribute(
      "src",
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
    )
    head.appendChild(script)
  }

  componentWillUnmount() {
    const calendlyWidget = document.getElementById("meeting-widget")
    const head = document.querySelector("head")
    head.removeChild(calendlyWidget)
  }

  render() {
    const infos = getContactInfos()

    return (
      <Layout>
        <SEO title="Contact" />
        <div className="contact-background iww-full-height">
          <div className="help-content">
            <h1 className="help-que-text">How can we help?</h1>
            <div className="help-text">
              IRIS AI, our end to end computer vision platform enables customers
              to use existing CCTV infrastructure to generate actionable
              insights
            </div>
          </div>
          <div className="contact-infos">
            {infos.map(ci => {
              return (
                <div key={ci.key} className="ci-tile">
                  <div className="cit-head">{ci.head}</div>
                  <OutboundLink
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cit-email"
                    href={"mailto:" + ci.email}
                  >
                    {ci.email}
                  </OutboundLink>
                  <div className="cit-number">{ci.cn}</div>
                  <div className="cit-addr">{ci.address}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="getin-touch">
          <div className="git-left">
            <div
              className="meetings-iframe-container"
              data-src="https://meetings.hubspot.com/subhash-sharma/website-meeting?embed=true"
            ></div>
            {/* <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/iwizards"
            ></div> */}
          </div>
          <div className="git-right"></div>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
