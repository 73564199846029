const contactInfos = [
  {
    key: "ci-india",
    head: "India",
    email: "info@iwizardsolutions.com",
    cn: "+91 80 4814 1584",
    address:
      "4th floor, 3M - 217, East of NGEF layout, ORR, Kasturinagar, Bangalore - 560043",
  },
  {
    key: "ci-europe",
    head: "Europe",
    email: "info.eu@iwizardsolutions.com",
    cn: "+44 1189 885193",
    address:
      "400 Thames Valley Park Drive\nThames Valley Park Reading Berkshire\nRG6 1PT United Kingdom",
  },
  {
    key: "ci-usa",
    head: "USA",
    email: "info.us@iwizardsolutions.com",
    cn: "+1 (236) 867-2885",
    address: "Suite b-2 Sunset Lake Road, Newark, Delaware 19702",
  },
  {
    key: "ci-canada",
    head: "Canada",
    email: "info.ca@iwizardsolutions.com",
    cn: "+1 (236) 867-2885",
    address: "1710-6588 Nelson Avenue, Burnaby, BC V5H 0E8, Canada",
  },
]

export const getContactInfos = () => contactInfos
